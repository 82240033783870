<template>
  <div>
    <b-card no-body>
      <b-card-header>
        <b-card-title>Hợp đồng cọc</b-card-title>
        <crud-buttons
          name="hợp đồng cọc"
          :selected-rows="selectedRows"
          modal="modal-depositTemplate"
          @on-delete="onDelete"
          @on-add="resetItem"
        />
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col>
            <b-form-input
              v-model="searchTerm"
              placeholder="Tìm kiếm..."
              debounce="1000"
            />
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col cols="12">
            <vue-good-table
              style-class="vgt-table striped bordered"
              mode="remote"
              :total-rows="totalRecords"
              :is-loading.sync="isLoading"
              :columns="columns"
              :rows="rows"
              :search-options="{
                enabled: false,
              }"
              :select-options="{
                enabled: true,
                selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: true, // disable the select info panel on top
                selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
              }"
              :pagination-options="{
                enabled: true,
                perPage: serverParams.perPage,
              }"
              @on-selected-rows-change="selectionChanged"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
            >
              <template slot="loadingContent">
                <b-spinner
                  label="Loading"
                  type="grow"
                />
              </template>
              <template
                slot="table-row"
                slot-scope="props"
              >
                <!-- Column: Code -->
                <span v-if="props.column.field === 'code'">
                  <b-link
                    v-b-modal.modal-preview-deposit-template
                    class="font-weight-bold"
                    @click="onEditItem(props.row)"
                  >
                    {{ props.row.code }}
                  </b-link>
                </span>

                <!-- Column: Default -->
                <span v-else-if="props.column.field === 'depositTemplate'">
                  <b-img
                    :src="props.row.depositTemplate"
                    height="80px"
                  />
                </span>

                <span v-else-if="props.column.field === 'default'">
                  <b-badge
                    :variant="props.row.default ? 'light-primary' : 'light-danger'"
                    class="font-small-1"
                  >
                    <span>{{ props.row.default ? "Có" : "Không" }}</span>
                  </b-badge>
                </span>

                <!-- Column: Action -->
                <span v-else-if="props.column.field === 'action'">
                  <table-actions
                    name="hợp đồng cọc"
                    modal="modal-depositTemplate"
                    @on-delete="onDelete(props.row)"
                    @on-edit="onEditItem(props.row)"
                  />
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> Hiển thị tối đa </span>
                    <b-form-select
                      v-model="serverParams.perPage"
                      :options="['10', '20', '50', '100', '500']"
                      class="mx-1"
                      @input="
                        (value) =>
                          props.perPageChanged({ currentPerPage: value })
                      "
                    />
                    <span class="text-nowrap">
                      trên tổng số {{ props.total }} kết quả
                    </span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="serverParams.perPage"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="
                        (value) => props.pageChanged({ currentPage: value })
                      "
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
              <div slot="emptystate">
                <div class="text-center text-muted">
                  Không có bản ghi nào!
                </div>
              </div>
            </vue-good-table>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <!-- modal -->
    <depositTemplate-modal
      :item="item"
      @refetch-data="refetchDepositTemplates"
    />
    <deposit-template-preview-modal
      :item="item"
    />
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BRow,
  BCol,
  BFormInput,
  BLink,
  BPagination,
  BFormSelect,
  BSpinner,
  BImg,
  BBadge,
} from 'bootstrap-vue';
import { VueGoodTable } from 'vue-good-table';
import TableActions from '@/views/components/TableActions.vue';
import CrudButtons from '@/views/components/CrudButtons.vue';
import useDepositTemplateList from './useDepositTemplateList';
// eslint-disable-next-line import/no-cycle
import DepositTemplateModal from '../modal/DepositTemplateModal.vue';
import DepositTemplatePreviewModal from '../preview/DepositTemplatePreviewModal.vue';

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BLink,
    BPagination,
    BFormSelect,
    BSpinner,
    BImg,
    BBadge,
    DepositTemplateModal,
    DepositTemplatePreviewModal,
    VueGoodTable,
    TableActions,
    CrudButtons,
  },
  setup() {
    const {
      item,
      resetItem,
      columns,
      rows,
      selectedRows,
      searchTerm,
      isLoading,
      totalRecords,
      serverParams,

      fetchDepositTemplates,
      deleteDepositTemplates,
      createDepositTemplate,
      refetchDepositTemplates,
      updateParams,
      onPageChange,
      onPerPageChange,
      onSortChange,
      onColumnFilter,
      selectionChanged,
      onEditItem,
    } = useDepositTemplateList();

    return {
      item,
      resetItem,
      columns,
      rows,
      selectedRows,
      searchTerm,
      isLoading,
      totalRecords,
      serverParams,

      refetchDepositTemplates,
      fetchDepositTemplates,
      deleteDepositTemplates,
      createDepositTemplate,

      updateParams,
      onPageChange,
      onPerPageChange,
      onSortChange,
      onColumnFilter,
      selectionChanged,
      onEditItem,
    };
  },

  created() {
    this.fetchDepositTemplates();
  },

  methods: {

    onDelete(depositTemplate) {
      const deleteObjects = depositTemplate && depositTemplate.id > 0 ? [depositTemplate] : this.selectedRows;
      this.deleteDepositTemplates(deleteObjects);
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
.vgt-wrap {
  .vgt-table {
    thead {
      th {
        font-size: 1rem !important;
        font-weight: 500;
      }
    }
    td {
      font-size: 1rem !important;
      font-weight: 500;
    }
  }
}
</style>
