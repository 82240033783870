import { ref, watch, onUnmounted } from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import depositTemplateStoreModule from '../depositTemplateStoreModule';

export default function useDepositTemplateList() {
  const STORE_MODULE_NAME = 'depositTemplate';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, depositTemplateStoreModule);
  }

  // UnRegister on leave
  onUnmounted(() => {
    if (store.hasModule(STORE_MODULE_NAME)) {
      store.unregisterModule(STORE_MODULE_NAME);
    }
  });
  // Use toast
  const toastification = toast();

  // add / edit
  const blankItem = {
    name: '',
    description: '',
    url: '',
    default: true,
    file: null,
  };
  const item = ref(JSON.parse(JSON.stringify(blankItem)));
  const resetItem = () => {
    item.value = JSON.parse(JSON.stringify(blankItem));
  };

  // Table Handlers
  const columns = [
    {
      label: 'Mã',
      field: 'code',
      width: '100px',
    },
    {
      label: 'Tên',
      field: 'name',
    },
    {
      label: 'Mặc định',
      field: 'default',
      width: '100px',
    },
    {
      label: 'Thao tác',
      field: 'action',
      sortable: false,
      width: '100px',
    },
  ];
  const rows = ref([]);
  // current selected rows
  const selectedRows = ref([]);
  const searchTerm = ref('');
  // ssr
  const isLoading = ref(false);
  const totalRecords = ref(0);
  const serverParams = ref({
    searchTerm: '',
    filter: {},
    sort: {},
    page: 1,
    perPage: 20,
  });

  //   API Call

  const fetchDepositTemplates = () => {
    store
      .dispatch('depositTemplate/fetchDepositTemplates', serverParams.value)
      .then(response => {
        const { total, items } = response.data;
        totalRecords.value = total;
        rows.value = items;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };
  const refetchDepositTemplates = () => {
    fetchDepositTemplates();
  };

  const deleteDepositTemplates = depositTemplates => {
    store
      .dispatch('depositTemplate/deleteDepositTemplates', {
        ids: depositTemplates.map(_obj => _obj.id),
      })
      .then(() => {
        fetchDepositTemplates();
        toastification.showToastDeleteSuccess();
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const createDepositTemplate = () => {
    store
      .dispatch('depositTemplate/createDepositTemplate', {
        name: item.value.name,
      })
      .then(() => {
        fetchDepositTemplates();
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  //   Methods
  const updateParams = newProps => {
    serverParams.value = { ...serverParams.value, ...newProps };
    fetchDepositTemplates();
  };

  const onPageChange = params => {
    updateParams({ page: params.currentPage });
  };

  const onPerPageChange = params => {
    updateParams({ perPage: params.currentPerPage });
  };

  const onSortChange = params => {
    updateParams({
      sort: {
        type: params[0].type,
        field: params[0].field,
      },
    });
  };

  const onColumnFilter = params => {
    updateParams(params);
  };

  const selectionChanged = params => {
    selectedRows.value = params.selectedRows;
  };

  const onEditItem = data => {
    item.value = data;
  };

  //   Watch
  watch(searchTerm, val => {
    updateParams({ searchTerm: val });
  });

  return {
    item,
    resetItem,

    columns,
    rows,
    selectedRows,
    searchTerm,
    isLoading,
    totalRecords,
    serverParams,

    refetchDepositTemplates,
    fetchDepositTemplates,
    deleteDepositTemplates,
    createDepositTemplate,

    updateParams,
    onPageChange,
    onPerPageChange,
    onSortChange,
    onColumnFilter,
    selectionChanged,
    onEditItem,
  };
}
